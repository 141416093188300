<template>
  <div class="subpage">
    <div class="title">
      <span>生涯测评</span>
      <span></span>
    </div>
    <div class="table">
      <el-table v-if="tableData" :cell-style="{ 'font-size': '16px' }" :header-cell-style="{
        'background-color': '#eeeeff',
        color: '#0F81FD',
        'letter-spacing': '3px',
      }" :data="tableData" border>
        <el-table-column prop="name" align="center" label="测评名称" width="260" />
        <el-table-column prop="create_time" align="center" label="测评时间" />
        <el-table-column align="center" width="239" label="操作">
          <template slot-scope="scope">
            <div @click="toAssess(scope.row.result, scope.row.name)" target="_blank" class="link">
              查看详情
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination v-if="total > 0" :page-size="pageSize" :total="total" class="page-box" background
        layout="prev, pager, next" @current-change="pageChange" />
    </div>
  </div>
</template>

<script>
export default {
  name: "TestReport",
  data() {
    return {
      tableData: [],
      pageNum: 1,
      pageSize: 10,
      total: 0,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    pageChange(page) {
      this.pageNum = page;
      this.getList();
    },
    toAssess(id, name) {
      console.log(name)
      // let routeData;
      switch (name) {
        case "情绪智力程度测评":
          
          window.open("https://www.apesk.com/mensa/common_report_getid/eq_report_admin.asp?id=" + id, "_blank");
          return;
        case "心理健康程度测评":
          // routeData = this.$router.resolve({
          //   path: "/sclResult?id=" + id,
          // });
          // window.open(routeData.href, "_blank");
          window.open("https://www.apesk.com/mensa/common_report_getid/scl90_report_admin.asp?SRId=" + id, "_blank");
          return;

        case "霍兰德测评":
          // routeData = this.$router.resolve({
          //   path: "/hollandResult?id=" + id,
          // });
          // window.open(routeData.href, "_blank");
          window.open('https://www.apesk.com/mensa/common_report_getid/holland2_report_getid.asp?id=' + id, "_blank");
          return;
        case "大学专业选择测评（新高考）":
          // routeData = this.$router.resolve({
          //   path: "/interestResult?id=" + id,
          // });
          window.open('https://www.apesk.com/lee/new/index_2_utf.asp?id=' + id, "_blank");
          return;
          // this.$router.push("/ucenter/testReport");
      }
    },
    getList() {
      this.$fecth
        .post("user_info/AppraisalList", {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        })
        .then((res) => {
          this.tableData = res.list;
          this.total = res.count;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.subpage {
  background-color: #ffffff;

  .title {
    font-size: 16px;
    height: 50px;
    line-height: 57px;
    padding: 0 30px;
    border-bottom: #ddd solid 1px;
  }

  .table {
    padding: 20px;
    margin: 30px 0;
    min-height: 300px;
  }

  .cell {
    font-size: 18px;
  }

  .link {
    color: #2066be;
  }

  .pagination {
    padding: 40px 0;

    .page-box {
      text-align: center;
    }
  }
}
</style>
